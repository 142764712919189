import Head from 'next/head'

import { Footer } from '@/components/Footer'
import { Header } from '@/components/Header'
import { Container } from '@/components/Container'
import Image from 'next/image'

export default function Home() {
  return (
    <>
      <Head>
        <title>Writana - Enrich your life through writing</title>
        <meta
          name="description"
          content="Enrich your life and improve your writing through guided prompts and exercises. Let us help you develop a writing habit that brings you joy and meaning."
        />
        <meta name="theme-color" content="#F1EAE2" />
      </Head>
      <Header />
      <main>
        <div className="overflow-hidden bg-paper py-12 sm:py-32 lg:pb-32 xl:pb-36">
          <Container>
            <div className="lg:grid lg:grid-cols-12 lg:gap-x-8 lg:gap-y-20">
              <div className="relative z-10 mx-auto max-w-2xl lg:col-span-5 lg:max-w-none lg:pt-6 xl:col-span-5">
                <h1 className="font-shift text-5xl text-primary">404</h1>
                <p className="mt-6 font-figtree text-lg text-primary">
                  This page doesn&apos;t exist.
                </p>
              </div>
              <div className="relative mt-10 sm:mt-20 lg:col-span-7 lg:row-span-2 lg:-mt-20 xl:col-span-7">
                <div className="flex w-full justify-center">
                  <Image
                    src={'/hat-collage.png'}
                    width={300}
                    height={569}
                    alt="magical imagination image"
                    className="justify-self-center"
                  />
                </div>
              </div>
            </div>
          </Container>
        </div>
      </main>
      <Footer />
    </>
  )
}
